<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="3">
        <v-autocomplete
          v-model="vehicleFilter"
          :items="vehicles"
          clearable
          item-text="name"
          item-value="id"
          :label="$t('filters.vehicle')"
          required
          :no-data-text="$t('select.noDataAvailable')"
          @focus="$event.target.click()"></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="3">
        <v-menu
          ref="fromDateFilter"
          v-model="fromDateFilter"
          :close-on-content-click="false"
          :return-value.sync="fromDate"
          min-width="auto"
          offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              clearable
              :label="$t('filters.fromDate')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <DatePicker
            v-model="fromDate"
            :model-config="filterModelConfig"
            mode="date"
            @input="updateFromDateFilter(fromDate)">
          </DatePicker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="3">
        <v-menu
          ref="toDateFilter"
          v-model="toDateFilter"
          :close-on-content-click="false"
          :return-value.sync="toDate"
          min-width="auto"
          offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              clearable
              :label="$t('filters.toDate')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <DatePicker
            v-model="toDate"
            :model-config="filterModelConfig"
            mode="date"
            @input="updateToDateFilter(toDate)">
          </DatePicker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <VehicleMaintenances
        :from-date="selectedFromDate"
        :to-date="selectedToDate"
        :vehicle-filter="vehicleFilter"
        :vehicles="vehicles" />
    </v-row>
  </v-container>
</template>

<script>
import { DatePicker } from 'v-calendar/src/components';
import VehicleMaintenances from '@/views/pages/vehicles/VehicleMaintenances';
import store from '@/store';

export default {
  name: 'VEMPG',
  components: { DatePicker, VehicleMaintenances },
  data: () => ({
    vehicleFilter: null,
    fromDateFilter: null,
    toDateFilter: null,
    fromDate: null,
    toDate: null,
    vehicleMaintenanceStartDate: null,
    vehicleMaintenanceEndDate: null,
    selectedToDate: null,
    selectedFromDate: null,
    vehicles: [],
    filterModelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
  }),
  created() {
    this.loadAllVehicles();
  },
  methods: {
    async loadAllVehicles() {
      await this.$store.dispatch('vehicles/getAllVehicles').then((res) => {
        this.vehicles = res.data;
      });
    },

    updateFromDateFilter(fromDate) {
      this.$refs.fromDateFilter.save(fromDate);
      this.selectedFromDate = fromDate;
    },
    updateToDateFilter(toDate) {
      this.$refs.toDateFilter.save(toDate);
      this.selectedToDate = toDate;
    },
  },

  watch: {},
};
</script>
